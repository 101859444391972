
import { Component, Vue } from "vue-property-decorator";
import Header from "@/components/header.vue"; // @ is an alias to /src
// import SidebarReports from "@/components/sidebar-reports.vue";
import Loader from "@/components/loader.vue"; // @ is an alias to /src
import TreeTable from "@/components/trialtable/TreeTable.vue";
// import HRMService from "../../../services/hrm-service";
import accountService from "@/services/account-service";
import FilterComponent from "@/components/sidebar-employee-filter.vue"
import FilterService from "@/services/filter-service"
@Component({
  components: {
    Header,
    Loader,
    // SidebarReports,
    TreeTable,
    FilterComponent,
  },
})
export default class BalanceSheet extends Vue {
  private data: any[] = [];
  public filter = null;
  public filterOn = [];
  loading = true;
  public currentItem = null;
  public currentIndex = -1;
  public currentPage = 1;
  public title = "";
  trans: any = [];
  selected_branch: any = {};
  public options_branch: any = [];
  public options_division: any = [];
  tableData: any = [];
  total: any = {};
  flattenedData: any = [];
  // public fields = [
  //   {
  //     key: "account",
  //   },
  //   {
  //     key: "description",
  //   },
  //   {
  //     key: "balance",
  //   },
  //   {
  //     key: "total",
  //   },
  // ];
  widths = [100, 220, 80, 80,]
  columns = [
    {
      label: "Code",
      id: "code",
    },
    {
      label: "Account",
      id: "account_title",
    },
    // {
    //   label: "Opening Balance Debit",
    //   id: "opening_balance_debit",
    // },
    // {
    //   label: "Opening Balance Credit",
    //   id: "opening_balance_credit",
    // },
    // {
    //   label: "Transaction Debit",
    //   id: "transaction_debit",
    // },
    // {
    //   label: "Transaction Credit",
    //   id: "transaction_credit",
    // },
    {
      label: "Debit Close",
      id: "debit_close",
    },
    {
      label: "Credit Close",
      id: "credit_close",
    },
  ];
  columnsData = [
    {
      label: "Code",
      field: "code",
    },
    {
      label: "Account",
      field: "account_title",
    },
    // {
    //   label: "Opening Balance Debit",
    //   field: "opening_balance_debit",
    // },
    // {
    //   label: "Opening Balance Credit",
    //   field: "opening_balance_credit",
    // },
    // {
    //   label: "Transaction Debit",
    //   field: "transaction_debit",
    // },
    // {
    //   label: "Transaction Credit",
    //   field: "transaction_credit",
    // },
    {
      label: "Debit Close",
      field: "debit_close",
    },
    {
      label: "Credit Close",
      field: "credit_close",
    },
  ];
  // get rows() {
  //   return this.selected_items.length;
  // }

  // public items = [];
  // public selected_items = [
  //   {
  //     id: 1,
  //     account: "9090903 - ALI AL-BUSAILI",
  //     description: "...",
  //     balance: 190322,
  //     total: 8000320,
  //   },
  //   {
  //     id: 2,
  //     account: "9090904 - ALI AL-BUSAILI",
  //     description: "...",
  //     balance: 1923322,
  //     total: 8000320,
  //   },
  //   {
  //     id: 3,
  //     account: "9090905 - USAMA AL-BUSAILI",
  //     description: "...",
  //     balance: 130322,
  //     total: 0,
  //   },
  //   {
  //     id: 4,
  //     account: "9090906 - FARHAN AL-BUSAILI",
  //     description: "...",
  //     balance: 43322,
  //     total: 0,
  //   },
  //   {
  //     id: 5,
  //     account: "9090907 - OWAIS AL-BUSAILI",
  //     description: "...",
  //     balance: 190322,
  //     total: 0,
  //   },
  //   {
  //     id: 6,
  //     account: "9090908 - KASHIF AL-BUSAILI",
  //     description: "...",
  //     balance: 134322,
  //     total: 0,
  //   },
  // ];
  retreive() {
    let obj = {
      "start_account_title": "",
      "end_account_title": "",
      "start_code": "01",
      "end_code": "03",
      "level_range_start": "",
      "level_range_end": ""
    };
    // HRMService.getAll()
    //   .then((response) => {
    //     this.items = response.data;
    //     this.selected_items = this.items;
    //     console.log(this.items)
    //   })
    //   .catch((e) => {
    //     console.log(e);
    //   });
    FilterService.getTrialBalance(obj).then((res) => {
      if (this.tableData.length === 0) {
        // console.log("no");
      }
      this.loading = true;
      this.tableData = res.data.data;
      this.total = res.data.total;
      this.flattenData(this.tableData);
      if (this.tableData.length > 0) {
        // console.log("yes");
      }
      this.loading = false;
    })
  }

  // refreshList() {
  //   this.retreive();
  //   this.currentItem = null;
  //   this.currentIndex = -1;
  // }

  // setActiveItem(data: any, index: number) {
  //   this.currentItem = data;
  //   this.currentIndex = index;
  // }

  // removeAll() {
  //   HrmService.deleteAll()
  //     .then((response) => {
  //       console.log(response.data);
  //       this.refreshList();
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // }

  // searchTitle() {
  //   HRMService.findByTitle(this.title)
  //     .then((response) => {
  //       this.data = response.data;
  //       console.log(response.data);
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // }

  mounted() {
    this.retreive();
  }
  flattenData(data: any, parentCode = null, level = 0) {
    for (const item of data) {
      // Add flattened item to the export data
      this.flattenedData.push({
        code: item.code,
        account_title: item.account_title,
        opening_balance_debit: item.opening_balance_debit,
        opening_balance_credit: item.opening_balance_credit,
        transaction_debit: item.transaction_debit,
        transaction_credit: item.transaction_credit,
        debit_close: item.debit_close,
        credit_close: item.credit_close,
      });

      // Recursively process children
      if (item.children && item.children.length > 0) {
        this.flattenData(item.children, item.code, level + 1);
      }
    }
  }
}
